import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	Button,
	RadioGroup,
	Radio,
	HTMLSelect,
} from '@blueprintjs/core';
import { DateInput3, IDateFormatProps } from '@blueprintjs/datetime2';

import moment from 'moment';
import formatISO from 'date-fns/formatISO';

import { addItem }                       from '../../slices/CodeTablesSlice';
import StockInventoryDetailedDialog      from './StockInventoryDetailedDialog';
import { loadStockInventoryItemsByDate } from '../../api/Warehouses';

import { ResultHeader, ResultHeaderWithSort, Result, MultiSelectFilter, TextFilter } from '../code_tables/ResultComponents';

import { debounce } from '../../App';
import { loadDateFnsLocale } from '../../utils';

function Results(props) {
	const {
		table_items_total_count,
		table_items,
		all_table_items,
		table_items_sort_ids,
		table_items_sort_field,
		table_items_sort_direction,
		setTableItemsSort,
		openDetailedDialog,
		tableItemsFilters,
		handleFilterChange,
		warehouses,
		suppliers,
		items,
	} = props;
	
	const sort_props = {
		setTableItemsSort:       setTableItemsSort,
		tableItemsSortField:     table_items_sort_field,
		tableItemsSortDirection: table_items_sort_direction,
	};
	
	const warehouses_with_empty = { ...warehouses };
	warehouses_with_empty.empty = { id_warehouse: 'empty', title: 'Brez skladišča' };
	
	return <>
		<div className='results mt-4 mb-4'>
			<div className='results-table overflow-y-auto sticky-header'>
				<ResultHeader columnIdx={1} title='Urejanje' />
				<ResultHeaderWithSort
					columnIdx={2} {...sort_props} title='Skladišče' fieldName='id_warehouse' />
				<ResultHeaderWithSort
					columnIdx={3} {...sort_props} title='Dobavitelj' fieldName='supplier_id_customer' />
				<ResultHeaderWithSort
					columnIdx={4} {...sort_props} title='Črtna koda' fieldName='ean' />
				<ResultHeaderWithSort
					columnIdx={5} {...sort_props} title='Šifra artikla' fieldName='internal_code' />
				<ResultHeaderWithSort
					columnIdx={6} {...sort_props} title='Naziv artikla' fieldName='title' />
				<ResultHeader
					columnIdx={7} title='Količina' cls='text-right' />
				<ResultHeader
					columnIdx={8} title='Nab. vred. brez DDV' cls='text-right' />
				<ResultHeader
					columnIdx={9} title='Nab. vred. z DDV' cls='text-right' />
				
				<Result columnIdx={1} cls='filters-col' />
				<Result columnIdx={2} cls='filters-col' child={
					<div className='flex flex-row'>
						<MultiSelectFilter
							empty_title    ='Izberi'
							value_field    ='id_warehouse'
							title_field    ='title'
							items          ={warehouses_with_empty}
							filtered_keys  ={tableItemsFilters.id_warehouses}
							displayFilter  ={x => true}
							displaySort    ={(a, b) => a.title < b.title ? -1 : (a.title > b.title ? 1 : 0)}
							onFilterChange ={values => handleFilterChange('id_warehouses', values)} />
					</div>
				} />
				<Result columnIdx={3} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.supplier_customer_name}
						onChange={ event => handleFilterChange('supplier_customer_name', event) } />
				} />
				<Result columnIdx={4} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.ean}
						onChange={ event => handleFilterChange('ean', event) } />
				} />
				<Result columnIdx={5} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.internal_code}
						onChange={ event => handleFilterChange('internal_code', event) } />
				} />
				<Result columnIdx={6} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.title}
						onChange={ event => handleFilterChange('title', event) } />
				} />
				<Result columnIdx={7} cls='filters-col' />
				<Result columnIdx={8} cls='filters-col' />
				<Result columnIdx={9} cls='filters-col' />
				
				{table_items_sort_ids.map((id, idx) => {
					const item      = table_items[id];
					const item_item = items[item.id_item];
					
					const supplier = item === undefined || item.supplier_id_customer === null ?
						undefined
						:
						suppliers[item.supplier_id_customer];
					let warehouse = item === undefined || item.id_warehouse === null ?
						undefined
						:
						warehouses[item.id_warehouse];
					if (warehouse === undefined && Object.keys(warehouses).length > 0) {
						warehouse = Object.values(warehouses)[0];
					}
					
					if (item !== undefined && item.real_count === null) {
						warehouse = null;
					}
					
					return <React.Fragment key={'items-result--result-' + id}>
						<Result columnIdx={1} child={
							<>
								<Button
									icon='edit'
									intent='primary'
									minimal={true}
									small={true}
									onClick={() => openDetailedDialog(item)} />
							</>
						} />
						<Result columnIdx={2} child={
							warehouse === undefined || warehouse === null ? '' : warehouse.title
						} />
						<Result columnIdx={3} child={
							supplier === undefined ? '' : (
								supplier.type == 'natural' ?
									supplier.surname + ' ' + supplier.name
									:
									supplier.company_name
							)
						} />
						<Result columnIdx={4} child={
							item_item.ean
						} />
						<Result columnIdx={5} child={
							item_item.internal_code
						} />
						<Result columnIdx={6} child={
							item_item.title
						} />
						<Result columnIdx={7} cls='text-right' child={
							parseFloat(
								item === undefined || item.real_count === null ? 0 : parseFloat(item.real_count)
							)
								.toLocaleString(
									undefined,
									{minimumFractionDigits: 2, maximumFractionDigits: 2}
								)
						} />
						<Result columnIdx={8} cls='text-right' child={
							parseFloat(
								item === undefined || item.price === null ? 0 : parseFloat(item.price)
							)
								.toLocaleString(
									undefined,
									{minimumFractionDigits: 2, maximumFractionDigits: 2}
								)
						} />
						<Result columnIdx={9} cls='text-right' child={
							parseFloat(
								item === undefined || item.price_with_vat === null ? 0 : parseFloat(item.price_with_vat)
							)
								.toLocaleString(
									undefined,
									{minimumFractionDigits: 2, maximumFractionDigits: 2}
								)
						} />
					</React.Fragment>;
				})}
			</div>
		</div>
		<div className='results-sum pb-4'>
			<div className='col'>
				Število zapisov: {table_items_total_count}
				{table_items_total_count > table_items_sort_ids.length ? ' (prikazanih je ' + table_items_sort_ids.length + ' zapisov)' : ''}
			</div>
			<div className='col text-right font-bold' style={{ flex: 0, flexBasis: '100px' }}>
				{
					Object.keys(all_table_items)
						.map(id => all_table_items[id].real_count === null ? 0 : parseFloat(all_table_items[id].real_count))
						.reduce((acc, val) => acc + val, 0)
						.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
				}
			</div>
			<div className='col text-right font-bold' style={{ flex: 0, flexBasis: '140px' }}>
				{
					Object.keys(all_table_items)
						.map(id => all_table_items[id].price === null ? 0 : parseFloat(all_table_items[id].price))
						.reduce((acc, val) => acc + val, 0)
						.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
				}
			</div>
			<div className='col text-right font-bold' style={{ flex: 0, flexBasis: '140px' }}>
				{
					Object.keys(all_table_items)
						.map(id => all_table_items[id].price_with_vat === null ? 0 : parseFloat(all_table_items[id].price_with_vat))
						.reduce((acc, val) => acc + val, 0)
						.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
				}
			</div>
		</div>
	</>;
}

class StockInventory extends Component {
	constructor(props) {
		super(props);
		
		this.updateTableItemsFromProps = this.updateTableItemsFromProps.bind(this);
		this.setTableItemsSort         = this.setTableItemsSort        .bind(this);
		this.refreshTableItemsSort     = this.refreshTableItemsSort    .bind(this);
		this.filterTableItems          = this.filterTableItems         .bind(this);
		this.handleFilterChange        = this.handleFilterChange       .bind(this);
		this.openAddEditItemDialog     = this.openAddEditItemDialog    .bind(this);
		this.closeAddEditItemDialog    = this.closeAddEditItemDialog   .bind(this);
		this.exportInventorySheet      = this.exportInventorySheet     .bind(this);
		this.exportInventory           = this.exportInventory          .bind(this);
		this.exportInventorySummary    = this.exportInventorySummary   .bind(this);
		
		this.updateTableItemsFromPropsDebounce = debounce((props, state) => {
			this.setState({
				...this.updateTableItemsFromProps(
					props,
					state
				),
			});
		}, 400);
		
		const table_items_filters = {
			id_warehouses:          [],
			supplier_customer_name: '',
			ean:                    '',
			internal_code:          '',
			title:                  '',
			type:                   'all',
		};
		
		const state = {
			table_items_filters,
			table_items_sort_field:     'internal_code',
			table_items_sort_direction: 'ASC',
			stock_inventory_items:      null,
			items:                      props.items,
		};
		
		const {
			table_items_total_count,
			table_items,
			all_table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
		} = this.updateTableItemsFromProps(props, {
			...state
		});
		
		this.state = {
			table_items_total_count,
			table_items,
			all_table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
			add_edit_item_dialog_open: false,
			add_edit_item_dialog_item: null,
			detailed_dialog_open:                false,
			detailed_dialog_internal_code:       '',
			detailed_dialog_document_date_range: [ null, null ],
			inventory_export_id_warehouse: 'empty',
			stock_date: new Date(),
			...state,
		};
	}
	
	componentDidMount() {
		loadStockInventoryItemsByDate(
			this.props.api_url,
			this.props.dispatch,
			this.props.token,
			moment(this.state.stock_date).format('YYYY-MM-DD')
		);
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		const stock_state = this.props.stock_inventory_by_date[moment(this.state.stock_date).format('YYYY-MM-DD')] === undefined ?
			null
			:
			this.props.stock_inventory_by_date[moment(this.state.stock_date).format('YYYY-MM-DD')].stock_state.last_stock_data;
		if (
			this.state.stock_state != stock_state ||
			this.state.items       != this.props.items
		) {
			this.setState({
				stock_state: stock_state,
				items:       this.props.items,
				...this.updateTableItemsFromProps(this.props, this.state),
			});
		}
		
		if (this.state.stock_date != prevState.stock_date && this.state.stock_date !== null) {
			loadStockInventoryItemsByDate(this.props.api_url, this.props.dispatch, this.props.token, moment(this.state.stock_date).format('YYYY-MM-DD'));
		}
	}
	
	updateTableItemsFromProps(props, state) {
		const items_from_props =
			props.stock_inventory_by_date[moment(state.stock_date).format('YYYY-MM-DD')] === undefined ?
				{}
				:
				props.stock_inventory_by_date[moment(state.stock_date).format('YYYY-MM-DD')].stock_state.last_stock_data;
		
		const {
			table_items_filtered_ids,
			table_items_filters,
			table_items_sort_ids,
		} = this.filterTableItems(
			state.table_items_filters,
			{
				table_items:                items_from_props,
				table_items_sort_field:     state.table_items_sort_field,
				table_items_sort_direction: state.table_items_sort_direction,
			},
			true
		);
		
		let items             = {};
		let selected_sort_ids = [];
		let all_items         = {};
		let c                 = 0;
		
		for (let i=0; i<table_items_sort_ids.length; i++) {
			const key = table_items_sort_ids[i];
			if (table_items_filtered_ids.indexOf(key) == -1) continue;
			
			if (c < 500) {
				items[key] = items_from_props[key];
				selected_sort_ids.push(key);
			}
			all_items[key] = items_from_props[key];
			c++;
		}
		
		const new_state = {
			table_items_total_count:    c,
			table_items:                items,
			all_table_items:            all_items,
			table_items_sort_ids:       selected_sort_ids,
			table_items_filtered_ids:   selected_sort_ids,
			table_items_sort_field:     state.table_items_sort_field,
			table_items_sort_direction: state.table_items_sort_direction,
		};
		
		new_state.table_items_filtered_ids = selected_sort_ids;
		new_state.table_items_filters      = table_items_filters;
		new_state.table_items_sort_ids     = selected_sort_ids;
		
		return new_state;
	}
	
	setTableItemsSort(field) {
		let direction = 'ASC';
		if (this.state.table_items_sort_field == field) {
			direction = (this.state.table_items_sort_direction == 'ASC' ? 'DESC' : 'ASC');
		}
		
		this.setState({
			table_items_sort_field:     field,
			table_items_sort_direction: direction,
		});
		
		this.refreshTableItemsSort(field, direction);
	}
	
	refreshTableItemsSort(field, direction, table_items_filtered_ids, state, return_only) {
		state                    = state || this.state;
		table_items_filtered_ids = table_items_filtered_ids || state.table_items_filtered_ids;
		
		const ids = table_items_filtered_ids;
		ids.sort((a_key, b_key) => {
			let a   = '';
			let b   = '';
			let dir = direction;
			
			if (field == 'title' || field == 'internal_code' || field == 'ean') {
				a = state.table_items[a_key];
				b = state.table_items[b_key];
				
				const item_a = this.props.items[a.id_item];
				const item_b = this.props.items[b.id_item];
				
				return item_a[field].localeCompare(item_b[field]) * (dir == 'ASC' ? 1 : -1);
			}
			else if (field == 'id_warehouse') {
				a = state.table_items[a_key];
				b = state.table_items[b_key];
				
				const stock_inventory_item_a = this.props.stock_inventory_by_date[moment(state.stock_date).format('YYYY-MM-DD')].stock_state.last_stock_data[a.id_item];
				const stock_inventory_item_b = this.props.stock_inventory_by_date[moment(state.stock_date).format('YYYY-MM-DD')].stock_state.last_stock_data[b.id_item];
				
				let warehouse_a = stock_inventory_item_a === undefined || stock_inventory_item_a.warehouse_id_customer === null ?
					undefined
					:
					this.props.warehouses[stock_inventory_item_a.id_warehouse];
				let warehouse_b = stock_inventory_item_b === undefined || stock_inventory_item_b.id_warehouse === null ?
					undefined
					:
					this.props.warehouses[stock_inventory_item_b.id_warehouse];
				
				if (warehouse_a === undefined && Object.keys(this.props.warehouses).length > 0) {
					warehouse_a = Object.values(this.props.warehouses)[0];
				}
				if (warehouse_b === undefined && Object.keys(this.props.warehouses).length > 0) {
					warehouse_b = Object.values(this.props.warehouses)[0];
				}
				
				const warehouse_title_a = warehouse_a === undefined ? '' : warehouse_a.title;
				const warehouse_title_b = warehouse_b === undefined ? '' : warehouse_b.title;
				
				return warehouse_title_a.localeCompare(warehouse_title_b) * (dir == 'ASC' ? 1 : -1);
			}
			else if (field == 'supplier_id_customer') {
				a = state.table_items[a_key];
				b = state.table_items[b_key];
				
				const stock_inventory_item_a = this.props.stock_inventory_by_date[moment(state.stock_date).format('YYYY-MM-DD')].stock_state.last_stock_data[a.id_item];
				const stock_inventory_item_b = this.props.stock_inventory_by_date[moment(state.stock_date).format('YYYY-MM-DD')].stock_state.last_stock_data[b.id_item];
				
				const supplier_a = stock_inventory_item_a === undefined || stock_inventory_item_a.supplier_id_customer === null ?
					undefined
					:
					this.props.customers[stock_inventory_item_a.supplier_id_customer];
				const supplier_b = stock_inventory_item_b === undefined || stock_inventory_item_b.supplier_id_customer === null ?
					undefined
					:
					this.props.customers[stock_inventory_item_b.supplier_id_customer];
				
				const supplier_title_a = supplier_a === undefined ? '' : (
					supplier_a.type == 'natural' ?
						supplier_a.surname + ' ' + supplier_a.name
						:
						supplier_a.company_name
				);
				const supplier_title_b = supplier_b === undefined ? '' : (
					supplier_b.type == 'natural' ?
						supplier_b.surname + ' ' + supplier_b.name
						:
						supplier_b.company_name
				);
				
				return supplier_title_a.localeCompare(supplier_title_b) * (dir == 'ASC' ? 1 : -1);
			}
			else {
				a = state.table_items[a_key][field];
				b = state.table_items[b_key][field];
			}
			
			return (a < b ? -1 : a > b ? 1 : 0) * (direction == 'ASC' ? 1 : -1);
		});
		
		const new_state = {
			table_items_sort_ids: ids,
		};
		
		if (!return_only) {
			this.setState(new_state);
		}
		return new_state;
	}
	
	filterTableItems(filters, state, return_only) {
		state = state || this.state;
		
		const filters_id_warehouses          = filters.id_warehouses;
		const filters_supplier_customer_name = filters.supplier_customer_name.trim().toUpperCase();
		const filters_ean                    = filters.ean                   .trim().toUpperCase();
		const filters_internal_code          = filters.internal_code         .trim().toUpperCase();
		const filters_title                  = filters.title                 .trim().toUpperCase();
		const filter_type                    = filters.type;
		
		const ids = state.table_items === null || state.table_items === undefined ? [] : Object.keys(state.table_items);
		const filtered_ids = ids.filter(id => {
			const item      = state.table_items[id];
			const item_item = this.props.items[item.id_item];
			
			// only show items
			if (item_item.type != 'item') return false;
			
			if (filter_type == 'equal-to-0' && (item === undefined || (item.real_count !== null && item.real_count != 0))) {
				return false;
			}
			else if (filter_type == 'larger-than-0' && (item === undefined || item.real_count <= 0)) {
				return false;
			}
			else if (filter_type == 'smaller-than-0' && (item === undefined || item.real_count >= 0)) {
				return false;
			}
			
			if (filters_id_warehouses.length > 0) {
				let found = false;
				let id_warehouse = item.real_count === null ? 'empty' : item.id_warehouse;
				if (id_warehouse === null && Object.keys(this.props.warehouses).length > 0) {
					id_warehouse = Object.keys(this.props.warehouses)[0];
				}
				for (let i=0; i<filters_id_warehouses.length; i++) {
					if (id_warehouse == filters_id_warehouses[i]) {
						found = true;
						break;
					}
				}
				if (!found) {
					return false;
				}
			}
			
			if (filters_supplier_customer_name.length > 0) {
				const supplier = item === undefined || item.supplier_id_customer === null ?
					undefined
					:
					this.props.customers[item.supplier_id_customer];
				
				if (supplier !== null && supplier !== undefined) {
					const supplier_customer_name = supplier.type == 'natural' ?
						supplier.surname + ' ' + supplier.name
						:
						supplier.company_name;
					if (supplier_customer_name.toUpperCase().indexOf(filters_supplier_customer_name) == -1) {
						return false;
					}
				}
				else {
					return false;
				}
			}
			
			if (filters_ean != '' && item_item.ean.toUpperCase().indexOf(filters_ean) == -1) {
				return false;
			}
			
			if (filters_internal_code != '' && item_item.internal_code.toUpperCase().indexOf(filters_internal_code) == -1) {
				return false;
			}
			
			if (filters_title != '' && item_item.title.toUpperCase().indexOf(filters_title) == -1) {
				return false;
			}
			
			return true;
		});
		
		const new_state = {
			table_items_filtered_ids: filtered_ids,
			table_items_filters:      filters,
		};
		
		if (!return_only) {
			this.setState(new_state);
		}
		
		const { table_items_sort_ids } = this.refreshTableItemsSort(
			state.table_items_sort_field,
			state.table_items_sort_direction,
			filtered_ids,
			{
				table_items_filtered_ids: new_state,
				table_items_filters:      new_state,
				table_items:              state.table_items,
			},
			return_only
		);
		
		new_state.table_items_sort_ids = table_items_sort_ids;
		
		return new_state;
	}
	
	handleFilterChange(field_name, event) {
		const new_filters = {
			id_warehouses:          this.state.table_items_filters.id_warehouses,
			supplier_customer_name: this.state.table_items_filters.supplier_customer_name,
			ean:                    this.state.table_items_filters.ean,
			internal_code:          this.state.table_items_filters.internal_code,
			title:                  this.state.table_items_filters.title,
			type:                   this.state.table_items_filters.type,
		};
		
		let val = event;
		if (val === null) { }
		if (val instanceof Date) { }
		else if (Array.isArray(val)) { }
		else if (val instanceof Object) {
			val = event.target.value;
		}
		new_filters[field_name] = val;
		
		this.setState({ table_items_filters: new_filters });
		this.updateTableItemsFromPropsDebounce(
			this.props,
			{
				...this.state,
				table_items_filters: new_filters,
			}
		);
	}
	
	openAddEditItemDialog(item) {
		this.setState({ add_edit_item_dialog_open: true, add_edit_item_dialog_item: item });
	}
	closeAddEditItemDialog(item) {
		this.setState({ add_edit_item_dialog_open: false });
		
		if (item !== undefined) {
			this.props.dispatch(addItem({ item, token: this.props.token, save_to_api: true }));
		}
	}
	
	exportInventorySheet() {
		const params = [
			'id_warehouse=' + this.state.inventory_export_id_warehouse,
			'date='         + moment(this.state.stock_date).format('YYYY-MM-DD'),
			'version=new',
		];
		const data = {
			id_warehouse: this.state.inventory_export_id_warehouse,
			date:         moment(this.state.stock_date).format('YYYY-MM-DD'),
		}
		
		const form = document.createElement('form');
		form.target = '_blank';
		form.method = 'POST';
		form.action = this.props.api_url +
			'Warehouses/v1/inventory-sheet-export-excel?' + 
			params.join('&');
		form.style.display = 'none';
		
		for (let key in data) {
			const input = document.createElement('input');
			input.type = 'hidden';
			input.name = key;
			input.value = data[key];
			form.appendChild(input);
		}
		
		document.body.appendChild(form);
		form.submit();
		document.body.removeChild(form);
	}
	
	exportInventory() {
		const params = [];
		const data   = {
			id_items: this.state.table_items_sort_ids.join(','),
			type:     this.state.table_items_filters.type,
			date:     moment(this.state.stock_date).format('YYYY-MM-DD'),
			version:  'new',
		}
		
		const form = document.createElement('form');
		form.target = '_blank';
		form.method = 'POST';
		form.action = this.props.api_url +
			'Warehouses/v1/inventory-export-excel?' + 
			params.join('&');
		form.style.display = 'none';
		
		for (let key in data) {
			const input = document.createElement('input');
			input.type = 'hidden';
			input.name = key;
			input.value = data[key];
			form.appendChild(input);
		}
		
		document.body.appendChild(form);
		form.submit();
		document.body.removeChild(form);
	}
	
	exportInventorySummary() {
		const params = [];
		const data   = {
			date: moment(this.state.stock_date).format('YYYY-MM-DD'),
		}
		
		const form = document.createElement('form');
		form.target = '_blank';
		form.method = 'POST';
		form.action = this.props.api_url +
			'Warehouses/v1/inventory-summary-export-excel?' + 
			params.join('&');
		form.style.display = 'none';
		
		for (let key in data) {
			const input = document.createElement('input');
			input.type = 'hidden';
			input.name = key;
			input.value = data[key];
			form.appendChild(input);
		}
		
		document.body.appendChild(form);
		form.submit();
		document.body.removeChild(form);
	}
	
	getMomentFormatter(format) {
		// note that locale argument comes from locale prop and may be undefined
		return { 
			formatDate: (date, locale) => moment(date).locale(locale).format(format),
		}
	}
	
	render() {
		const suppliers = this.props.customers; //.filter(x => x.supplier === true);
		
		return <>
			{!this.state.detailed_dialog_open ? null :
				<StockInventoryDetailedDialog
					filter_internal_code={this.state.detailed_dialog_internal_code}
					filter_document_date_range={this.state.detailed_dialog_document_date_range}
					closeDialog={() => this.setState({ detailed_dialog_open: false })} />
			}
			
			<div className='flex flex-col flex-grow stock-inventory-list'>
				<div className='pl-4 pt-4 flex justify-between items-center'>
					<div>
						<Button
							className='mr-2'
							intent='primary'
							onClick={() => this.setState({
								detailed_dialog_open:                true,
								detailed_dialog_internal_code:       '',
								detailed_dialog_document_date_range: [ new Date(), new Date() ],
							})}>
							Kartica artikla
						</Button>
						
						<Button
							className='mr-2'
							intent='primary'
							icon='cloud-download'
							disabled={this.state.table_items_sort_ids.length == 0}
							onClick={this.exportInventory}>
							Izvoz v Excel (artikli)
						</Button>
						<Button
							className='mr-2'
							intent='primary'
							icon='cloud-download'
							onClick={this.exportInventorySummary}>
							Izvoz v Excel (sumarno)
						</Button>
						
						<HTMLSelect
							className='ml-3'
							options={
								Object.values({ empty: { id_warehouse: 'empty', title: 'Brez skladišča' }, ...this.props.warehouses })
								.map(
									item => {
										return { label: item.title, value: item.id_warehouse };
									}
								)
							}
							value={this.state.inventory_export_id_warehouse}
							onChange={(e) => {
								this.setState({
									inventory_export_id_warehouse: e.currentTarget.value,
								});
							}} />
						<Button
							className='ml-1'
							intent='primary'
							icon='cloud-download'
							onClick={this.exportInventorySheet}>
							Inventurni list
						</Button>
					</div>
					<div className='flex flex-row items-center p-1'>
						<div>Stanje na dan:</div>
						
						<div className='ml-1 mr-2'>
							<DateInput3
								dateFnsLocaleLoader={loadDateFnsLocale}
								dateFnsFormat={'dd. MM. yyyy'}
								locale='sl'
								inputProps={{ placeholder: '01. 01. ' + new Date().getFullYear(), className: 'w-24' }}
								selectAllOnFocus={true}
								minDate={new Date('1900-01-01')}
								maxDate={moment().add('10', 'years').toDate()}
								onChange={(selectedDate, isUserChange) => {
									if (isUserChange) this.setState({ stock_date: selectedDate === null ? null : new Date(selectedDate) });
								}}
								value={this.state.stock_date === null ? null : formatISO(this.state.stock_date)} />
						</div>
						
						<RadioGroup
							className='pt-1'
							onChange={event => this.handleFilterChange('type', event)}
							inline={true}
							selectedValue={this.state.table_items_filters.type}>
							<Radio className='mb-2' label='Vsa zaloga' value='all' />
							<Radio className='mb-2' label='Brez zaloge' value='equal-to-0' />
							<Radio className='mb-2' label='Zaloga, večja od 0' value='larger-than-0' />
							<Radio className='mb-2' label='Zaloga, manjša od 0' value='smaller-than-0' />
						</RadioGroup>
					</div>
				</div>
				
				<div className='flex-1 pl-4 pr-4'>
					<div className='flex-grow overflow-y-auto' style={{ flexBasis: '0' }}>
						<Results
							table_items_total_count={this.state.table_items_total_count}
							table_items={this.state.table_items}
							all_table_items={this.state.all_table_items}
							table_items_sort_ids={this.state.table_items_sort_ids}
							table_items_sort_field={this.state.table_items_sort_field}
							table_items_sort_direction={this.state.table_items_sort_direction}
							setTableItemsSort={this.setTableItemsSort}
							openDetailedDialog={item => {
								this.setState({
									detailed_dialog_open:                true,
									detailed_dialog_internal_code:       this.props.items[item.id_item].internal_code,
									detailed_dialog_document_date_range: [ null, null ],
								});
							}}
							tableItemsFilters={this.state.table_items_filters}
							handleFilterChange={this.handleFilterChange}
							warehouses={this.props.warehouses}
							suppliers={suppliers}
							items={this.props.items} />
					</div>
				</div>
			</div>
		</>;
	}
}
StockInventory.propTypes = {
};

function mapStateToProps(state) {
	return {
		items:                   state.CodeTablesSlice.items,
		warehouses:              state.CodeTablesSlice.warehouses,
		customers:               state.CodeTablesSlice.customers,
		stock_state:             state.WarehouseSlice.stock_state,
		stock_inventory_by_date: state.WarehouseSlice.stock_inventory_by_date,
		api_url:                 state.WarehouseSlice.api_url,
		token:                   state.UserSlice.token,
	};
}

export default connect(mapStateToProps)(StockInventory);
